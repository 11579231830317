@use 'spacing';
@use 'breakpoints';
@use 'br24-common';

.styledChevron {
	margin-right: spacing.$s200;
}

.title {
	position: relative;

	display: inline-flex;
	align-items: center;

	padding: spacing.$s400;

	opacity: 1;
}

.titleLink {
	position: relative;

	display: inline-flex;
	align-items: center;

	margin: spacing.$s400;

	font-size: 1.25rem;

	opacity: 1;

	@include br24-common.link-focus;

	&:hover {
		text-decoration: underline;
	}
}

.wrapper {
	width: 100%;
	background-color: var(--background-default-first);
	border-bottom: 5px solid var(--background-button-first);
}

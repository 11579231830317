@use 'spacing';
@use 'breakpoints';
@use 'br24-common';

.wrapper {
	@supports (contain: inline-size) {
		container: radio-widget / size;

		// explicit height is necessary because container resets the ability to size elements based on their content
		// min height is the height of the button and icon combined plus the padding
		min-height: br24-common.pixel-to-rem(88px) + 2 * spacing.$s400;
	}

	display: flex;
	justify-content: center;

	padding: spacing.$s400 spacing.$s800;

	color: var(--text-consistent-od-01);

	background-color: var(--background-default-third);
}

.container {
	display: flex;
	flex: 1;
	flex-direction: row;
	gap: spacing.$s1000;
	align-items: center;
	justify-content: space-between;

	max-width: br24-common.pixel-to-rem(512px) - 2 * spacing.$s400;

	@container radio-widget (height > 200px) {
		flex-direction: column-reverse;
		gap: 0;
		align-items: center;
		justify-content: space-evenly;
	}

	@media screen and (min-width: breakpoints.$lg) {
		max-width: none;
	}
}

.textLeftContent {
	display: flex;
	flex-direction: column;
	gap: spacing.$s100;
	justify-content: center;
}

.right {
	display: flex;
	flex-direction: column;
	gap: spacing.$s200;
	align-items: center;
	align-self: center;
}

.button {
	display: flex;
	gap: spacing.$s400;
	align-items: center;

	padding: spacing.$s200 spacing.$s400;

	color: var(--text-consistent-od-01);
	white-space: nowrap;

	background: var(--background-button-second);
	border: 0;

	&:visited {
		color: var(--text-consistent-od-01);
	}

	&:hover,
	&:focus-visible,
	&:visited:hover {
		color: var(--text-consistent-ol);
		background: var(--background-highlight-first);
	}

	@include br24-common.link-focus;
}

.text {
	overflow: hidden;
	text-overflow: ellipsis;

	@supports (-webkit-line-clamp: 1) {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
	}
}

.radioTitle {
	overflow: hidden;
	text-overflow: ellipsis;

	@supports (-webkit-line-clamp: 1) {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
	}
}

@use 'br24-common';

.listItem {
	display: inline;
}

.authorLink {
	color: var(--text-default-second);

	&:visited {
		color: var(--text-default-second);
	}

	&:hover,
	&:focus-visible {
		color: var(--text-highlight-second);
	}

	&:hover {
		text-decoration: underline;
	}

	@include br24-common.link-focus;
}

.name {
	display: inline;
}

@use 'spacing';
@use 'breakpoints';
@use 'br24-common';

.wrapper {
	flex: 1;
	width: 100%;
	background-color: var(--background-default-first);

	&:not(:last-child) {
		border-bottom: 1rem solid var(--background-color);

		@media only screen and (min-width: breakpoints.$lg) {
			border-bottom: none;
		}
	}
}

.isLargeWrapper {
	flex: 2;
}

.content {
	padding: spacing.$s400 spacing.$s600;
}

.header {
	display: flex;
	flex-direction: column;
}

.title {
	margin-top: spacing.$s400;
}

.teaser {
	margin-top: spacing.$s500;
}

.authorsComments {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	color: var(--text-default-second);
}

.dot {
	margin-inline: spacing.$s200;
}

.metaModules {
	position: relative;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	color: var(--text-default-second);
}

.articleLink {
	cursor: pointer;
	color: var(--text-default-first);

	&:hover {
		color: var(--text-highlight-second);
		text-decoration: underline;
	}

	@include br24-common.link-focus;

	&:visited {
		color: var(--text-default-first);

		&:hover {
			color: var(--text-highlight-second);
		}

		&:focus-visible {
			color: var(--text-default-first);
		}
	}

	@supports (-webkit-line-clamp: 1) {
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;

		text-overflow: ellipsis;
	}
}

.commentsLink {
	display: inline;
	color: var(--text-default-second);
	word-break: keep-all;
	white-space: nowrap;

	&:hover {
		color: var(--text-highlight-second);
		text-decoration: underline;
	}

	&:visited {
		color: var(--text-default-second);
	}

	@include br24-common.link-focus;
}

.isHero {
	margin-top: 0;
}

.line {
	width: 3rem;
	height: br24-common.pixel-to-rem(2px);
	margin: spacing.$s600 spacing.$s0;

	background-color: var(--text-default-first);
	border: 0;
}

.footer {
	margin-top: spacing.$s500;
}

@use 'spacing';
@use 'breakpoints';
@use 'br24-common';

.textDiv {
	padding: spacing.$s600;
	hyphens: auto;
	background: var(--background-default-first);
	border-top: spacing.$s400 solid var(--background-default-second);

	@media only screen and (min-width: breakpoints.$lg) {
		padding-inline: 0;
	}
}

.headline {
	margin-bottom: spacing.$s300;
}

.innerDiv {
	position: relative;

	@media only screen and (min-width: breakpoints.$lg) {
		max-width: breakpoints.$lg - 2 * spacing.$s600;
		margin: 0 auto;
	}
}

@use 'br24-common';

$button-dimension-m: br24-common.pixel-to-rem(2 * 10px + 24px);
$button-dimension-s: br24-common.pixel-to-rem(2 * 5px + 24px);

@mixin component-style($className) {
	#{$className} {
		display: flex;
		place-items: center center;
		justify-content: center;

		color: var(--icon-consistent-ol);

		border: none;
		border-radius: 50%;

		&:focus-visible {
			outline: 2px double var(--text-highlight-second);
		}

		&.primary,
		&.tertiary:not(:focus-visible, :hover, :active, :focus) {
			background-color: var(--background-icon-first);
		}

		&.secondary:not(:focus-visible, :hover, :active, :focus) {
			background-color: var(--background-icon-second);
		}

		&.tertiary,
		&.secondary {
			&:hover,
			&:focus,
			&:active {
				background-color: var(--background-highlight-first);
			}
		}

		&:hover,
		&:focus,
		&:focus-visible,
		&:active {
			cursor: pointer;

			& .icon {
				transform: scale(1.1);
			}
		}
	}

	.m {
		width: $button-dimension-m;
		height: $button-dimension-m;
	}

	.s {
		width: $button-dimension-s;
		height: $button-dimension-s;
	}

	.icon {
		transition-timing-function: ease-in-out;
		transition-duration: 200ms;
		transition-property: color, transform;
	}
}

@use 'br24-common';
@import './variables';

.playButton {
	pointer-events: all;
	position: relative;
	appearance: none;

	@include br24-common.link-focus(--background-highlight-first);
}

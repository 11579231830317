@use 'sass:math';

/// Removes the unit from a number
///
/// @param {number} $number the number to remove the unit from
@function strip-unit($number, $unit: 1px) {
	@return calc($number / ($number * 0 + $unit));
}

/// Converts pixel units to rem units using the specified font size
///
/// @param {number} $pxValue
///     The value to convert to rem
/// @param {number} $font-size
///     The base font size for rem calculation (default 16px)
@function pixel-to-rem($pxValue, $font-size: 16px) {
	// check if units are pixel values
	@if math.unit($pxValue) != 'px' {
		@error ("Parameter $pxValue must be in px units but has unit #{math.unit($value)}");
	}
	@if math.unit($font-size) != 'px' {
		@error ("Parameter $font-size must be in px units but has unit #{math.unit($font-size)}");
	}

	@return calc(strip-unit($pxValue) / strip-unit($font-size)) * 1rem;
}

/// Resets the visited link color for dark regions of the page as it is overwritten
/// in a global css stylesheet.
///
/// @param {string} $color
///     The color to set for the visited link state
@mixin visited-link-color($color: --text-consistent-od-01, $outline: --text-highlight-second) {
	@at-root a:visited#{&},
		a#{&} {
		color: var($color);

		&:focus-visible {
			outline: 3px double var($outline);
			outline-offset: 2px;
		}
	}
}

/// Implements the highlight on hover behavior for links in the header section
///
/// @param {string} $element
///     The element (e.g. `a`) to prepend to the selector to make the selector more specific.
@mixin highlight-on-hover($element: '', $color: --text-highlight-first) {
	transition: color 200ms ease-in-out;

	@at-root #{$element}#{&}:hover {
		color: var($color);
	}
}

$navigation-item-height: pixel-to-rem(56px);

/// Implements the focus behavior for links in the text section
@mixin link-focus($color: --text-highlight-second) {
	&:active {
		outline: none;
	}

	&:focus-visible {
		outline: 3px double var($color);
		outline-offset: 2px;
	}
}

$header-z-index: 1;

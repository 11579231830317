@use 'spacing';
@use 'breakpoints';
@use 'br24-common';

.videoSectionTitle {
	display: flex;
	justify-content: center;
	padding: spacing.$s400;
	color: var(--text-consistent-od-01);
}

.videoSection {
	display: flex;
	flex: 1;
	flex-direction: column;

	margin-bottom: spacing.$s400;

	background-color: var(--background-default-third);

	@media only screen and (min-width: breakpoints.$lg) {
		margin-bottom: 0;
	}
}

.bannerSection {
	z-index: 4;

	display: flex;
	flex: 1;
	gap: spacing.$s600;
	align-items: center;
	justify-content: center;

	padding: spacing.$s300 spacing.$s1000;

	color: var(--text-consistent-od-01);

	&:active {
		outline: none;
	}

	&:hover {
		color: var(--text-highlight-first);
	}

	&:visited {
		color: var(--text-consistent-od-01);

		&:hover,
		&:focus {
			color: var(--text-highlight-first);
		}
	}

	@include br24-common.link-focus(--text-highlight-first);
}

.bannerMediathek {
	text-align: center;
}

.externalLink {
	flex-shrink: 0;
}

@use 'br24-common';
@use 'spacing';

.titleLink {
	display: block;
	color: var(--text-default-first);

	&:hover {
		color: var(--text-highlight-second);
		text-decoration: underline;
	}

	@include br24-common.link-focus;
}

.isHighlightTitleLink {
	margin-bottom: 2rem;
	color: var(--text-consistent-od-01);

	&:hover,
	&:focus-visible {
		color: var(--text-highlight-first);
	}

	&:visited {
		color: var(--text-consistent-od-01);

		&:hover,
		&:focus-visible {
			color: var(--text-highlight-first);
		}
	}

	@include br24-common.link-focus(--text-highlight-first);
}
